<template>
  <div>
    <v-dialog v-model="showing" max-width="600px">
      <template #activator="{ on }">
        <v-btn small color="primary" v-on="on" depressed>
          Preciso de ajuda
          <v-icon small right>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-btn
          icon
          @click="showing = false"
          class="ma-2"
          style="position: absolute; right: 0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-subtitle class="pb-0 pt-4 text-caption" style="height: 0">
          Ajuda Sobre
        </v-card-subtitle>
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text
          class="grey--text text--darken-3 py-0 my-0"
          :style="{
            height: contentHeight + 'px',
            'overflow-y': 'auto',
          }"
        >
          <slot />
        </v-card-text>
        <v-card-actions class="pb-6 px-5">
          <v-row no-gutters>
            <v-col v-if="!!video" class="d-flex">
              <v-btn
                color="primary white--text text-caption font-weight-bold"
                class="flex-grow-1 mx-1"
              >
                <v-icon size="23" left>mdi-play-circle-outline</v-icon>
                Ver Vídeo Explicativo
              </v-btn>
            </v-col>
            <v-col class="d-flex">
              <v-btn
                color="green darken-1 white--text text-caption font-weight-bold"
                class="flex-grow-1 mx-1"
                :href="`https://wa.me/554930030944?text=2 - Estou com dúvida no eConsenso. ${message}`"
                target="_blank"
              >
                <v-icon size="23" left>mdi-whatsapp</v-icon>
                Chamar no Whatsapp
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "HelpDialog",

  props: {
    title: { require: true, type: String },
    content: { require: true, type: String },
    video: { default: undefined, type: String },
    message: { default: "", type: String },
  },

  data: () => ({
    showing: false,
  }),

  computed: {
    contentHeight() {
      return this.$vuetify.breakpoint.height - 250;
    },
  },
};
</script>
